(function() {
  'use strict';
  angular
    .module('app')
    // .constant('BASE_URL', 'http://dataweb.lvh.me:4007')
    // .constant('DOMAIN_URL', 'http://dataweb.lvh.me:3000')
    // .constant('CENTINELA_URL', 'http://lvh.me:4004')
    // .constant('OPERADORA_URL', 'http://lvh.me:4000')
    //************ With SSL
    //Variables de integracion
    .constant('HASH_CABALLOS', 'fbb9c413-f157-11ee-95dc-bc2411bfd8ae')
    .constant('_', _)
    .constant('accounting', accounting)
    .constant('swal', 'swal')
    .constant('moment', moment);
})();
  
(function () {
  'use strict';
  angular
  .module('app')
  .component('rifamax', {
    templateUrl: 'app/components/rifamax/rifamax.html',
    controller: RifamaxController
  });

  RifamaxController.$inject = ['$state', 'miscelaneos', 'user','$stateParams', 'cashierService','$rootScope','$uibModal','$sce','ActionCableChannel','$timeout'];

  function RifamaxController($state, $miscelaneos, $user, $stateParams, cashierService, $rootScope, $uibModal, $sce, ActionCableChannel, $timeout) {
    var vm = this;
    var channel;
    
    vm.$onInit = function() {
      vm.loading = true;
      vm.currentUser = $user.getCurrentUser();
      vm.loteria_id = null
      if ($stateParams.cashier_id == '') {
        getAllCashiers(vm.loteria_id)
      } else {
        if (vm.currentUser.cajero.currency == 'Bs' && vm.currentUser.pay_pago_movil) {
          showModalPagoMovil();
        }
        vm.urlRifamax = 'https://admin.rifa-max.com/x100_integrador?token=rm_live_ed8c46ee-06fb-4d12-b194-387ddb3578d0&player_id='+vm.currentUser.id+'&currency='+vm.currentUser.cajero.currency+'&user_type='+vm.currentUser.tipo_estructura
        vm.loading = false;
      }
      if (!channel) {
        channel = new ActionCableChannel("PrinterChannel", {});
        var callback = function(notification) {
          console.log("Callback ejecutado", new Date()); // Agrega timestamp para verificar frecuencia
          vm.notification = notification;
          if (vm.notification.estructura_id == vm.currentUser.id) {
            getNotification(vm.notification);
          }
        };
        
        channel.subscribe(callback).then(function() {
          console.log('Conectado al Canal');
        });
      }
    }



    vm.$onDestroy = function(){

      if($stateParams.cashier_id != ''){
        var params = {
          client: {
            integrator_id: vm.currentUser.id,
            integrator_type: "CDA"
          }
        }
        var response = $miscelaneos.clearRifaMax(params)
        console.log(response)
      }

      if (channel) {
        channel.unsubscribe();
        console.log("Desuscrito del canal");
      }
    }

    function getAllCashiers(loteria_id) {
      cashierService.getAllCashiers(loteria_id)
        .then(function (res) {
          vm.allCashiers = res;
          showWallets(res);
        })
    }
  
    function showWallets(allCashiers) {
      var modal = $uibModal.open({
        animation: true,
        component: 'modalAllCashiers',
        windowClass: 'modalAllCashiers',
        backdrop: 'static',
        keyboard: false,
        size: 'xs',
        resolve: {
          allCashiers: function () {
            return allCashiers;
          }
        }
      })
      modal.result.then(function (res) {
        var oldCashier = vm.currentUser.cajero;
        var newFavorite = _.findWhere(res, { checked: true });
        if (newFavorite) {
          vm.loadingCajeroWeb = true;
          vm.currentUser.cajero = newFavorite;
          $user.setCurrentUser(vm.currentUser);
          $rootScope.$broadcast('updatedUser', vm.currentUser);
          $state.go("rifamax", { "cashier_id": vm.currentUser.cajero.id });
        }
      })
    }
  
    function showModalPagoMovil() {
      var modal = $uibModal.open({
        animation: true,
        component: 'modalPagoMovil',
        windowClass: 'modalPagoMovil',
        backdrop: 'static',
        keyboard: false,
        size: 'xs',
      })
      modal.result.then(function (res) {
        vm.pagoMovil = res;
      })
    }

    vm.trustSrc = function(src) {
      return $sce.trustAsResourceUrl(src);
    }

    function getNotification(data){
      var res = data;
      vm.ticket = $sce.trustAsHtml(angular.copy(res.ticket.replace(/\n|\r/g, '<br>')));
      vm.ticket_ws = angular.copy(res.ticket.replace(/\\n/g, '<br>'));
      vm.ticket_print = angular.copy(res.ticket.replace(/\n|\r/g, '\n'));
      $timeout(function(){
        console.log('showTicket','rifamax')
        showTicket(vm.ticket, vm.ticket_print, vm.ticket_ws, res.codagen_info, res.nt)
      },0)
    }
  
    function showTicket(ticket, ticket_print, ticket_ws, codagen_info, tracking_id) {
      var modal = $uibModal.open({
        animation: true,
        component: 'gModalTicket',
        backdrop: 'static',
        windowClass: 'modalTicket',
        size: 'xs',
        resolve: {
          ticket: function () {
            return ticket;
          },
          ticket_print: function () {
            return ticket_print;
          },
          ticket_ws: function () {
            return ticket_ws;
          },
          codagen_info: function(){
            return codagen_info;
          },
          tracking_id: function(){
            return tracking_id 
          }
        },
      })
    }
  }

})();

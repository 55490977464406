angular
.module('app')
.component('webHeader', {
  templateUrl: 'app/components/web-header/web-header.html',
  controller: WebHeaderController
});

WebHeaderController.$inject = ['user', 'authService','$rootScope','$window','miscelaneos','$uibModal', '$state', 'cashierService'];

function WebHeaderController($user, $authService, $rootScope, $window, $miscelaneos, $uibModal, $state, $cashierService) {
  var vm = this;
  vm.logout = logout;
  vm.goToPS = goToPS;
  vm.backToCDA = backToCDA;
  vm.activateCoupon = activateCoupon;

  vm.$onInit = function(){
    vm.currenState = $state.current.name;
    vm.currentUser = $user.getCurrentUser();
    if(vm.currentUser){
      // $miscelaneos.getNewNotifications()
      // .then(function(res){
      //   vm.countNotifications = res;
      // })
    }

  }

  function logout() {
    $authService.logout();
    vm.currentUser = null;
    $window.location = '/login';
  }

  function goToPS(){
    var modal = $uibModal.open({
      animation: true,
      component: 'loadingModal',
      backdrop: 'static',
      size: 'md',
      resolve: {
        tipo: function() {
          return 1;
        }
      }
    });

    modal.result.then(function(res){
      $window.location = '/lobby';
    }, function(err){

    })
  }

  function backToCDA(){
    var modal = $uibModal.open({
      animation: true,
      component: 'loadingModal',
      backdrop: 'static',
      size: 'md',
      resolve: {
        tipo: function() {
          return 2;
        }
      }
    });

    modal.result.then(function(res){
      $window.location = '/panel-de-agentes';
    }, function(err){

    })
  }

  function activateCoupon(){
    $window.swal({
      title: 'Indique el código del bono',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        required: 'true'
      },
      showCancelButton: true,
      confirmButtonText: 'Agregar',
      cancelButtonText:'Cancelar',
      showLoaderOnConfirm: true,
      inputValidator: function(result) {
        return !result && 'Indique el código del bono'
      },
      preConfirm: function(code) {
        return $cashierService.activeCoupon({code: code})
        .then(function(response){
          $rootScope.$broadcast('updateCupon', response.cupon);
          return response.message;
        }, function(err){
          throw new Error(err.data.message)
        })
        .catch(function(error){
          $window.swal.showValidationError(error)
        })
      },
      allowOutsideClick: function(){
        !$window.swal.isLoading()
      }
    }).then(function(result) {
      if (result.value) {
        $window.swal('Exito','Bono aplicado con éxito','success');
      }
    })
  }

  function updateCurrentUser(data){
    vm.currentUser = data;
  }

  vm.redirectToDeposit = function(){
    // $state.go('deposit.agentspanel', {"moneda_id": vm.currentUser.cajero.moneda_id})
  }


  $rootScope.$on('updateCountNotification',function(event,data){
    vm.countNotifications = data;
  });
  $rootScope.$on('updatedUser',function(event,data){
    updateCurrentUser(data);
  });
  $rootScope.$on('updateCajeroUser', function(evt, data){
    delete vm.currentUser.cajero
    vm.currentUser.cajero = data;
    $user.setCurrentUser(vm.currentUser);
  });
  $rootScope.$on('updateProfile', function(ev, data){
    vm.currentUser = $user.getCurrentUser();
  })
  $rootScope.$on('updateNameState', function(ev, data){
    vm.currenState = data.state;
  })
}
